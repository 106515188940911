import ReactDOM from 'react-dom'
import Button from './Button'
import React, { useEffect, useState, useRef } from 'react'
import './scss/SignatureModal.scss'
import SignatureCanvas from 'react-signature-canvas'
import axiosInstance from '../api/axiosInstance'
import { useDispatch, useSelector } from 'react-redux'
import { singFile } from '../actions/authActions'
import { rootReducer } from '../store/store'

interface SginState {
  driver1Sgin: boolean
  driver2Sgin: boolean
}

type AuthState = ReturnType<typeof rootReducer>
interface ModalProps {
  isOpen: boolean
  onClose: () => void
  children?: any
  driverNum: number
  setSignImg?: React.Dispatch<React.SetStateAction<string>> | any
  driverListProp?: Array<any> | any
  setDriverListProp?: React.Dispatch<React.SetStateAction<string>> | any
  setDriverSginState: React.Dispatch<React.SetStateAction<SginState>>
}

const SignatureModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  driverNum,
  driverListProp,
  setDriverSginState
}) => {
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const signatureCanvasRef = useRef<any>()
  const handleResetSignature = () => {
    signatureCanvasRef.current.clear() // 서명 캔버스 리셋
    driverListProp.singImg = null
    if (driverNum === 1) {
      setDriverSginState((prev: SginState) => ({
        ...prev,
        driver1Sgin: false
      }))
    } else {
      setDriverSginState((prev: SginState) => ({
        ...prev,
        driver2Sgin: false
      }))
    }
  }
  const dispatch = useDispatch()


  const resInfo = useSelector((state: AuthState) => state.store)
  const data = resInfo.resInfo && resInfo.resInfo.data

  if (!isOpen) return null

  const handleSaveSignature = async () => {
    if (!signatureCanvasRef.current.isEmpty()) {
      const signatureImageURL = signatureCanvasRef.current.toDataURL()

      driverListProp[driverNum - 1].singImg = signatureImageURL

      // 데이터 URL을 Base64 인코딩된 문자열로 변환 (백엔드에서 처리 중...)
      // const encodedImage = signatureImageURL.split(",")[1];

      if (driverNum === 1) {
        dispatch(singFile(signatureImageURL))
        setDriverSginState((prev: SginState) => ({
          ...prev,
          driver1Sgin: true
        }))
      } else {
        dispatch(singFile(signatureImageURL))
        setDriverSginState((prev: SginState) => ({
          ...prev,
          driver2Sgin: true
        }))
      }

      await axiosInstance()
        .post('/Mobile/V1/MobileSignUpload.do', {
          companyId: data?.companyId,
          reservationId: data?.reservationId,
          signNature: signatureImageURL,
          driverType: driverNum,
        })
        .then((res) => {
          if (res.data.result === 'success') {
            onClose()
            // driverListProp[driverNum - 1].singImg = null
          }
        })
    }
  }
  return ReactDOM.createPortal(
    <>
      {/* <img src={signImage}></img> */}
      <div className="modal-overlay" />
      <div className="modal-container">
        <div className="modal-content">
          <div className="modal-header">
            <div style={{ width: '100%' }}>
              <button onClick={handleResetSignature}>지우기</button>
            </div>
            <div style={{ width: '100%', fontWeight: '700' }}>서명하기</div>
            <div>
              <button className="modal-close" onClick={() => {onClose(); setIsSigned(false)}}>
                닫기
              </button>
            </div>
          </div>
          <div style={{
            color: '#ee0000',
            fontWeight: '600',
            fontSize: '0.9em',
            marginLeft: '5px'
          }}>
            !서명은 정자로 해주세요.
          </div>
          <div className='sign-placeholder-wrap'>
          {driverListProp[driverNum - 1]?.driverName || ''}
          </div>
          <div className="modal-sign">
            <SignatureCanvas
              canvasProps={{ className: 'canvasStyle' }}
              ref={signatureCanvasRef}
              onBegin={() => {setIsSigned(true)}}
            />
          </div>
        </div>
        <Button onClick={handleSaveSignature}>등록하기</Button>
      </div>
    </>,
    document.getElementById('modal-root') as HTMLElement,
  )

  
}

export default SignatureModal
