import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import React, { ChangeEvent, useState, useEffect } from "react";
import Step from "../components/Step";
import TitleHeader from "../components/TitleHeader";
import Input from "../components/Input";
import "./scss/driverInfo.scss";
import Select from "react-select";
import Button from "../components/Button";
import { driver, file } from "../actions/authActions";
import Swich from "../components/Swich";
import CommonModal from "../components/CommonModal";
import { rootReducer } from "../store/store";
import { defaultDriver, Driver } from "../types/DriverTypes";
import { useCommonUtil } from "../hook/useCommonUtil";
import axiosInstance from "../api/axiosInstance";
import moment from "moment";
import { transpileModule } from "typescript";
type AuthState = ReturnType<typeof rootReducer>;

const DriverInfo: React.FC = () => {
  const {
    formatPhoneNumber,
    formatDate,
    getNextDayIfPast,
    calculateDrivingExperience,
    calculateAge,
    isValidDate,
    formatLicenseType,
    formatBirthTime,
    addHyphenToLicenseNumber,
    calculateYearsSinceIssued,
    sliceLicenseNumber,
  } = useCommonUtil();
  const [driverList, setDriverList] = useState<Driver[]>(defaultDriver);
  const [showModal, setShowModal] = useState(false);
  const [alertTitle, setAlertTitle] = useState<string>("입력정보 확인요청");
  const [alertContent, setAlertContent] = useState<string>("");
  const [licenseImage, setLicenseImage] = useState<FileList | null>(null);
  const [thumbnail, setThumbnail] = useState("");
  const [driver2Chk, setdDriver2Chk] = useState(false);
  const [licenseImage2, setLicenseImage2] = useState<FileList | null>(null);
  const [thumbnail2, setThumbnail2] = useState("");
  const [emContactNumber, setEmContactNumber] = useState("");
  const [modalCloseFun, setModalCloseFun] = useState(() => () => setShowModal(false));

  const dispatch = useDispatch();
  const resInfo = useSelector((state: AuthState) => state.store.resInfo);

  const history = useHistory();

  const license = [
    { value: "01001", label: "1종 보통" },
    { value: "01003", label: "1종 대형" },
    { value: "02001", label: "2종 보통" },
  ];

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = e.target.files;
    const fileData: File | null = fileList ? fileList[0] : null;

    setLicenseImage(fileList);

    if (fileData) {
      const maxFileSize = 6 * 1024 * 1024;
      if (fileData.size > maxFileSize) {
        setAlertContent("6MB 이상의 파일은 업로드가 불가능합니다.");
        setShowModal(true);
        return; 
      }

      
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const imageSrc = e.target.result.toString();

          // Image 객체 생성
          const img = new Image();
          img.src = imageSrc;

          img.onload = () => {
            // 유효한 이미지임을 확인 후 처리
            setThumbnail(imageSrc);

            const updatedDriverList = [...driverList];
            updatedDriverList[0] = {
              ...updatedDriverList[0],
              changeLicense: true,
            };
            setDriverList(updatedDriverList);
          };

          img.onerror = () => {
            // 유효하지 않은 이미지임을 처리
            setAlertContent(`유효하지 않은 이미지 파일입니다`);
            setShowModal(true);
            setThumbnail(""); // 썸네일을 지우거나 기본 이미지로 변경
            return false;
          };
        }
      };
      reader.readAsDataURL(fileData);
    } else {
      setThumbnail("");
    }
  };

  const handleFileChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList: FileList | null = e.target.files;
    const fileData: File | null = fileList ? fileList[0] : null;

    setLicenseImage2(fileList);

    if (fileData) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          const imageSrc = e.target.result.toString();

          // Image 객체 생성
          const img = new Image();
          img.src = imageSrc;

          img.onload = () => {
            // 유효한 이미지임을 확인 후 처리
            console.log("이미지 로드 성공");
            setThumbnail2(imageSrc);

            const updatedDriverList = [...driverList];
            updatedDriverList[1] = {
              ...updatedDriverList[1],
              changeLicense: true,
            };
            setDriverList(updatedDriverList);
          };

          img.onerror = () => {
            // 유효하지 않은 이미지임을 처리
            setAlertContent(`유효하지 않은 이미지 파일입니다`);
            setShowModal(true);
            setThumbnail2(""); // 썸네일을 지우거나 기본 이미지로 변경
          };
        }
      };
      reader.readAsDataURL(fileData);
    } else {
      setThumbnail2("");
    }
  };

  // FileList 합치는 함수
  const mergeFileLists = (
    fileList1: FileList | null,
    fileList2: FileList | null
  ): File[] | null => {
    if (fileList1 && fileList2) {
      const combinedFiles: File[] = Array.from(fileList1).concat(
        Array.from(fileList2)
      );

      return combinedFiles;
    } else if (fileList1) {
      return Array.from(fileList1);
    } else if (fileList2) {
      return Array.from(fileList2);
    } else {
      return null;
    }
  };

  const handleSelectChange = (arg: number) => (selectedOption: any) => {
    console.log("selectedOption", selectedOption);
    driverList[arg].licenseType = selectedOption.value;
  };

  const nextValidate = () => {
    if (!driver2Chk) {
      if (
        !driverList[0].tel ||
        !driverList[0].driverName ||
        !driverList[0].birthday ||
        !driverList[0].licenseType ||
        !driverList[0].licenseNum ||
        !driverList[0].licenseDate ||
        !thumbnail ||
        !emContactNumber
      ) {
        return true;
      }
    } else if (driver2Chk) {
      if (
        !driverList[0].tel ||
        !driverList[0].driverName ||
        !driverList[0].birthday ||
        !driverList[0].licenseType ||
        !driverList[0].licenseNum ||
        !driverList[0].licenseDate ||
        !thumbnail ||
        !driverList[1].tel ||
        !driverList[1].driverName ||
        !driverList[1].birthday ||
        !driverList[1].licenseType ||
        !driverList[1].licenseNum ||
        !driverList[1].licenseDate ||
        !thumbnail2
      ) {
        return true;
      }
    }
    return false;
  };

  const handleClick = async () => {
    const res = await validateForm();

    if(res) {
      goContract();
    } 
  };

  const goContract = () => {
    if(showModal) {
      setShowModal(false);
    }

    if (!driver2Chk) {
      driverList[0].tel = formatPhoneNumber(driverList[0].tel);
      driverList[0].birthday = formatDate(driverList[0].birthday);
      driverList[0].emContactNumber =
        formatPhoneNumber(emContactNumber || "") || "";
      driverList.pop();
    } else {
      driverList[0].tel = formatPhoneNumber(driverList[0].tel);
      driverList[0].emContactNumber =
        formatPhoneNumber(emContactNumber || "") || "";
      driverList[1].tel = formatPhoneNumber(driverList[1].tel);
      driverList[0].birthday = formatDate(driverList[0].birthday);
      driverList[1].birthday = formatDate(driverList[1].birthday);
    }

    dispatch(driver(driverList));
    const combinedFileList: FileList | any = mergeFileLists(
      licenseImage,
      licenseImage2
    );

    dispatch(file(combinedFileList));

    const formData = new FormData();
    if (combinedFileList && combinedFileList.length > 0) {
      for (let i = 0; i < combinedFileList.length; i++) {
        formData?.append("file", combinedFileList[i]);
      }
    }

    console.log("driverList", driverList);
    history.push("/contract");
  };

  const validateForm = async () => {
    let check: boolean[] = [];
    for (let i = 0; i < driverList.length; i++) {
      const driver = driverList[i];
      if (i === 1 && !driver2Chk) {
        continue; // 부운전자가 체크되지 않은 경우, 검증을 건너뜁니다.
      }

      if (!isValidDate(formatDate(driver.birthday))) {
        setAlertContent(`생년월일 형식이 잘못 되었습니다.`);
        setShowModal(true);
        return false;
      }

      // if (!isValidDate2(driver.licenseDate)) {
      //   setAlertContent(`면허 취득일 형식이 잘못 되었습니다.`)
      //   setShowModal(true)
      //   return false
      // }

      if (!driver2Chk && emContactNumber.trim() === "") {
        setAlertContent("비상 연락처를 입력해주세요.");
        setShowModal(true);
        return false;
      }

      const licenseDate = calculateDrivingExperience(
        String(driver.licenseDate)
      );
      console.log(driverList[i]);
      console.log(driverList[i].licenseNum);

      const licenseNum = sliceLicenseNumber(
        addHyphenToLicenseNumber(driverList[i].licenseNum)
      );

      const licenseYear =
        calculateYearsSinceIssued(Number(licenseNum)) <
        Number(resInfo?.data.rentCondLicenseYear);
      console.log(licenseNum);
      if (licenseYear) {
        setAlertContent(
          `운전 연차 ${resInfo?.data.rentCondLicenseYear}년 이상 사용 가능합니다.`
        );
        setShowModal(true);
        return false;
      }

      const birthday = calculateAge(driver.birthday);

      if (resInfo?.data?.rentCondAge) {
        if (Number(birthday) < Number(resInfo?.data?.rentCondAge)) {
          setAlertContent(
            `만 나이 ${resInfo?.data?.rentCondAge}세 이상 사용 가능합니다.`
          );
          setShowModal(true);
          return false;
        }
      }

      if (Number(birthday) > 75) {
        setAlertContent("만 75세 넘을 경우 대여가 가능하지 않은 나이입니다.");
        setShowModal(true);
        return false;
      }

      const carInsuranceValidation =
        resInfo?.data?.carInsuranceChargeInfoArray &&
        resInfo?.data?.carInsuranceChargeInfoArray.some((e) => {
          if (Number(birthday) < e.carInsuranceInfo.minAge) {
            setAlertContent(
              `${e.carInsuranceInfo.insuranceName} 보험은 만 나이 ${e.carInsuranceInfo.minAge}세 이상 사용 가능합니다.`
            );
            setShowModal(true);
            return true;
          }
          if (
            calculateYearsSinceIssued(Number(licenseNum)) <
            e.carInsuranceInfo.minCareer
          ) {
            setAlertContent(
              `${e.carInsuranceInfo.insuranceName} 보험은 운전 연차 ${e.carInsuranceInfo.minCareer}년 이상 사용 가능합니다.`
            );
            setShowModal(true);
            return true;
          }
          return false;
        });

      if (carInsuranceValidation) {
        return false;
      }
      if (
        driver.driverName.trim() === "" ||
        driver.tel.trim() === "" ||
        driver.birthday.trim() === "" ||
        driver.licenseType.trim() === "" ||
        driver.licenseNum.trim() === "" ||
        (i === 0 && thumbnail.trim() === "") ||
        (i === 1 && !thumbnail2)
      ) {
        setAlertContent("필수 값을 입력해주세요.");
        setShowModal(true);
        return false;
      }

      const res = await equlesDlvLicense(driver);

      if (res && !showModal) {
        driver.licenseCheckDtm = moment().format("YYYY-MM-DD HH:mm:ss");
      } 
      check.push(!!res);
    }

    return check.every((result) => result);
  };

  const equlesDlvLicense = async (driver: Driver) => {
    const req = {
      licenseNo: driver.licenseNum,
      residentName: driver.driverName,
      residentDate: driver.birthday,
      seqNo: "",
      licenseConCode: formatLicenseType(driver.licenseType),
      fromDate: getNextDayIfPast(
        new Date(
          resInfo?.data.takeOverStrDtm ? resInfo?.data.takeOverStrDtm : ""
        )
      ),
      toDate: getNextDayIfPast(
        new Date(resInfo?.data.returnStrDtm ? resInfo?.data.returnStrDtm : "")
      ),
      exportCarId: resInfo?.data.carId,
    };

    return await axiosInstance()
      .post(
        `/Mobile/V1/${resInfo?.data.companyId}/DriverLicenseValidator.do`,
        req
      )
      .then((res) => {
        if(res.data && res.data.result === "success") {
          return true;
        } else {
          if (res.data.message.includes("검증시스템에서")) {
            setAlertTitle("면허 검증 시스템 장애");
            setAlertContent("면허 검증 시스템 오류로 인해\n 데스크에서 차키 수령시\n직원분이 면허정보를 추가 확인하게 됩니다.");
            setShowModal(true);
            setModalCloseFun(() => () => {
              goContract();
            });

            return false;
          } else {
            setAlertTitle("면허 검증 실패");
            setAlertContent(res.data.message || "");
            setShowModal(true);

            return false;
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const Driver2Check = () => {
    setdDriver2Chk(!driver2Chk);
  };

  const handleDriverChange = (index: number, field: string, value: any) => {
    const updatedDriverList = [...driverList];
    updatedDriverList[index] = {
      ...updatedDriverList[index],
      [field]: value,
    };
    setDriverList(updatedDriverList);
  };

  useEffect(() => {
    const updatedDriverList = [...driverList]; // 현재 상태를 복제
    if (resInfo?.data.driver1stName) {
      updatedDriverList[0] = {
        ...updatedDriverList[0],
        driverName: resInfo.data.driver1stName,
      };
    }
    if (resInfo?.data.driver1stTel) {
      updatedDriverList[0] = {
        ...updatedDriverList[0],
        tel: resInfo.data.driver1stTel.replace(/-/g, ""),
      };
    }
    if (resInfo?.data.driver1stBirthday) {
      updatedDriverList[0] = {
        ...updatedDriverList[0],
        birthday: formatBirthTime(Number(resInfo.data.driver1stBirthday)),
      };
    }

    if (resInfo?.data.driver1stLicenseNum) {
      updatedDriverList[0] = {
        ...updatedDriverList[0],
        licenseNum: resInfo.data.driver1stLicenseNum,
      };
    }

    if (resInfo?.data.driverList) {
      updatedDriverList[0] = {
        ...updatedDriverList[0],
        licenseType: resInfo.data.driverList[0]
          ? resInfo.data.driverList[0].licenseType
          : "01001",
      };
    }

    if (resInfo?.data.driver1stLicenseImage) {
      setThumbnail(
        `${process.env.REACT_APP_API_URL}/Common/File/Download.do?fileId=${resInfo.data.driver1stLicenseImage}`
      );
    }

    if (resInfo?.data.driver1stFileGroupId) {
      updatedDriverList[0] = {
        ...updatedDriverList[0],
        licenseFileGroupId: resInfo.data.driver1stFileGroupId,
      };
    }

    if (resInfo?.data.emergencyTel) {
      updatedDriverList[0] = {
        ...updatedDriverList[0],
        emContactNumber: resInfo.data.emergencyTel.replace(/-/g, ""),
      };
      setEmContactNumber(resInfo.data.emergencyTel.replace(/-/g, ""));
    }

    if (resInfo?.data.driver2ndName) {
      Driver2Check();
      updatedDriverList[1] = {
        ...updatedDriverList[1],
        driverName: resInfo.data.driver2ndName,
      };
    }
    if (resInfo?.data.driver2ndTel) {
      updatedDriverList[1] = {
        ...updatedDriverList[1],
        tel: resInfo.data.driver2ndTel.replace(/-/g, ""),
      };
    }

    if (
      resInfo?.data.driverList[1] &&
      resInfo.data.driverList[1].typeDateBirthday
    ) {
      updatedDriverList[1] = {
        ...updatedDriverList[1],
        birthday: formatBirthTime(
          Number(
            resInfo.data.driverList[1]
              ? resInfo.data.driverList[1].typeDateBirthday
              : 0
          )
        ),
      };
    }

    if (resInfo?.data.driverList) {
      updatedDriverList[1] = {
        ...updatedDriverList[1],
        licenseType: resInfo.data.driverList[1]
          ? resInfo.data.driverList[1].licenseType
          : "01001",
      };
    }

    if (resInfo?.data.driver2ndLicenseNum) {
      updatedDriverList[1] = {
        ...updatedDriverList[1],
        licenseNum: resInfo.data.driver2ndLicenseNum,
      };
    }

    if (resInfo?.data.driver2ndFileGroupId) {
      updatedDriverList[1] = {
        ...updatedDriverList[1],
        licenseFileGroupId: resInfo.data.driver2ndFileGroupId,
      };
    }

    if (resInfo?.data.driver2ndLicenseImage) {
      setThumbnail2(
        `${process.env.REACT_APP_API_URL}/Common/File/Download.do?fileId=${resInfo.data.driver2ndLicenseImage}`
      );
    }

    console.log("updatedDriverList", updatedDriverList);

    setDriverList(updatedDriverList); // 수정된 상태로 업데이트
  }, []);
  return (
    <>
      <div className="driver1-root">
        <Step phase={"3"}></Step>
        <TitleHeader title={"운전자 정보"}></TitleHeader>

        <div className="driver1-info" style={{ marginTop: "10px" }}>
          <div className="driver1-label-wrap">
            <span className="driver1-label">운전자명</span>
            <span className="driver1-label-etc">(필수)</span>
          </div>
          <Input
            type="text"
            value={driverList[0].driverName ? driverList[0].driverName : ""}
            onChange={(e) =>
              handleDriverChange(0, "driverName", e.target.value)
            }
            placeholder={"운전자 실명 입력"}
          />
        </div>

        <div className="driver1-info">
          <div className="driver1-label-wrap">
            <span className="driver1-label">연락처</span>
            <span className="driver1-label-etc">(필수)</span>
          </div>
          <Input
            type={"number"}
            value={driverList[0].tel ? driverList[0].tel : ""}
            maxLength={11}
            onChange={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              handleDriverChange(0, "tel", e.target.value);
            }}
            placeholder={"- 제외 숫자만 입력"}
          />
        </div>

        <div className="driver1-info">
          <div className="driver1-label-wrap">
            <span className="driver1-label">생년월일</span>
            <span className="driver1-label-etc">(필수)</span>
          </div>
          <Input
            type={"number"}
            value={driverList[0].birthday ? driverList[0].birthday : ""}
            maxLength={6}
            onChange={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              handleDriverChange(0, "birthday", e.target.value);
            }}
            placeholder={"YYMMDD(6자리 입력)"}
          />
        </div>

        <div className="driver1-info">
          <div className="driver1-label-wrap">
            <span className="driver1-label">면허종류</span>
            <span className="driver1-label-etc">(필수)</span>
          </div>
          <Select
            styles={{
              indicatorSeparator: () => ({ display: "none" }),
              control: (base, state: any) => ({
                ...base,
                boxShadow: "none",
                outline: state.isFocused ? "1px solid #4667d9" : "",
                borderColor: "#ccc",
              }),
              menu: (base) => ({
                ...base,
                marginTop: 3,
                borderRadius: 0,
              }),
            }}
            onChange={handleSelectChange(0)}
            options={license}
            defaultValue={
              resInfo?.data.driverList[0]
                ? license.find(
                    (item) =>
                      item.value === resInfo?.data.driverList[0].licenseType
                  )
                : license[0]
            }
          />
          <div style={{ marginTop: "5px", color: "#464c52" }}>
            <span>
              *10인승 초과 승합차량은 1종 보통 이상 면허만 운전 가능합니다.
            </span>
          </div>
        </div>

        <div className="driver1-info">
          <div className="driver1-label-wrap">
            <span className="driver1-label">면허번호</span>
            <span className="driver1-label-etc">(필수)</span>
          </div>
          <Input
            type={"text"}
            value={driverList[0].licenseNum}
            maxLength={12}
            onChange={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              handleDriverChange(0, "licenseNum", e.target.value);
            }}
            placeholder={"-제외 12자리"}
          />
        </div>

        {/* <div className="driver1-info">
          <div className="driver1-label-wrap">
            <span className="driver1-label">면허 취득일</span>
            <span className="driver1-label-etc">(필수)</span>
          </div>
          <Input
            type={'number'}
            value={driverList[0].licenseDate}
            maxLength={8}
            onChange={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength)
              handleDriverChange(0, 'licenseDate', e.target.value)
            }}
            placeholder={'YYYYMMDD (8자리 입력)'}
          />
        </div> */}

        <div className="driver1-info">
          <div className="driver1-label-wrap">
            <span className="driver1-label">면허증 사진</span>
            <span className="driver1-label-etc">(필수)</span>

            <label className="driver1-img-wrap">
              {!thumbnail && <div className="dirver1-img-select">파일선택</div>}

              {thumbnail && (
                <div className="driver1-thumbnail-wrap">
                  <img
                    className="thumbnail"
                    src={thumbnail}
                    alt="Selected Thumbnail"
                  />
                </div>
              )}
              <input
                type="file"
                onChange={handleFileChange}
                accept="image/gif,image/jpeg,image/png"
              ></input>
            </label>
            <div className="img-ment">
              *주민번호 뒷자리만 가려서 면허번호, 주소가 보이게 촬영해주세요.
              <br /> *빛 반사없이 면허증이 화면에 꽉차게 사진을 가로로
              촬영해주세요.
            </div>
          </div>
        </div>
      </div>
      <div className="line"></div>
      {/* -------------------부운전자 영역------------------- */}
      <div className="driver1-root">
        <div className="driver2-check">
          <TitleHeader title={"부운전자 정보"}>
            <Swich onChange={Driver2Check} value={driver2Chk}></Swich>
          </TitleHeader>
        </div>
        {driver2Chk && (
          <>
            <div className="driver1-info" style={{ marginTop: "10px" }}>
              <div className="driver1-label-wrap">
                <span className="driver1-label">운전자명</span>
                <span className="driver1-label-etc">(필수)</span>
              </div>
              <Input
                type="text"
                value={driverList[1].driverName}
                onChange={(e) =>
                  handleDriverChange(1, "driverName", e.target.value)
                }
                placeholder={"운전자 실명 입력"}
              />
            </div>
            <div className="driver1-info">
              <div className="driver1-label-wrap">
                <span className="driver1-label">연락처</span>
                <span className="driver1-label-etc">(필수)</span>
              </div>
              <Input
                type={"number"}
                maxLength={11}
                value={driverList[1].tel}
                onChange={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  handleDriverChange(1, "tel", e.target.value);
                }}
                placeholder={"- 제외 숫자만 입력"}
              />
            </div>

            <div className="driver1-info">
              <div className="driver1-label-wrap">
                <span className="driver1-label">생년월일</span>
                <span className="driver1-label-etc">(필수)</span>
              </div>
              <Input
                type={"number"}
                value={driverList[1].birthday ? driverList[1].birthday : ""}
                onChange={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  handleDriverChange(1, "birthday", e.target.value);
                }}
                maxLength={6}
                placeholder={"YYMMDD(6자리 입력)"}
              />
            </div>

            <div className="driver1-info">
              <div className="driver1-label-wrap">
                <span className="driver1-label">면허종류</span>
                <span className="driver1-label-etc">(필수)</span>
              </div>
              <Select
                styles={{
                  indicatorSeparator: () => ({ display: "none" }),
                  control: (base, state: any) => ({
                    ...base,
                    boxShadow: "none",
                    outline: state.isFocused ? "1px solid #4667d9" : "",
                    borderColor: "#ccc",
                  }),
                  menu: (base) => ({
                    ...base,
                    marginTop: 3,
                    borderRadius: 0,
                    zIndex: 99999,
                  }),
                }}
                options={license}
                defaultValue={
                  resInfo?.data.driverList[1]
                    ? license.find(
                        (item) =>
                          item.value === resInfo?.data.driverList[1].licenseType
                      )
                    : license[0]
                }
                onChange={handleSelectChange(1)}
              />
              <div style={{ marginTop: "5px", color: "#464c52" }}>
                <span>
                  *10인승 초과 승합차량은 1종 보통 이상 면허만 운전 가능합니다.
                </span>
              </div>
            </div>
            <div className="driver1-info">
              <div className="driver1-label-wrap">
                <span className="driver1-label">면허번호</span>
                <span className="driver1-label-etc">(필수)</span>
              </div>
              <Input
                type={"text"}
                value={driverList[1].licenseNum}
                onChange={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  handleDriverChange(1, "licenseNum", e.target.value);
                }}
                maxLength={12}
                placeholder={"- 제외 12자리"}
              />
            </div>

            {/* <div className="driver1-info">
              <div className="driver1-label-wrap">
                <span className="driver1-label">면허 취득일</span>
                <span className="driver1-label-etc">(필수)</span>
              </div>
              <Input
                type={'number'}
                value={driverList[1].licenseDate}
                onChange={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(0, e.target.maxLength)
                  handleDriverChange(1, 'licenseDate', e.target.value)
                }}
                maxLength={8}
                placeholder={'YYYYMMDD (8자리 입력)'}
              />
            </div> */}
            <div className="driver1-info">
              <div className="driver1-label-wrap">
                <span className="driver1-label">면허증 사진</span>
                <span className="driver1-label-etc">(필수)</span>

                <label className="driver1-img-wrap">
                  {!thumbnail2 && (
                    <div className="dirver1-img-select">파일선택</div>
                  )}

                  {thumbnail2 && (
                    <div className="driver1-thumbnail-wrap">
                      <img
                        className="thumbnail"
                        src={thumbnail2}
                        alt="Selected Thumbnail"
                      />
                    </div>
                  )}
                  <input
                    type="file"
                    onChange={handleFileChange2}
                    accept="image/gif,image/jpeg,image/png"
                  ></input>
                </label>
                <div className="img-ment">
                  *주민번호 뒷자리만 가려 서 면허번호, 주소가 보이게
                  촬영해주세요.
                  <br /> *빛 반사없이 면허증이 화면에 꽉차게 사진을 가로로
                  촬영해주세요.
                </div>
              </div>
            </div>
          </>
        )}
        {!driver2Chk && (
          <div className="driver1-info" style={{ marginTop: "10px" }}>
            <div className="driver1-label-wrap">
              <span className="driver1-label">비상연락처</span>
              <span className="driver1-label-etc">(필수)</span>
            </div>
            <Input
              type={"number"}
              value={emContactNumber}
              maxLength={11}
              onChange={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                setEmContactNumber(e.target.value);
              }}
              placeholder={"- 제외 숫자만 입력"}
            />
          </div>
        )}
      </div>

      {showModal && (
        <CommonModal
          onClose={modalCloseFun}
          title={alertTitle}
          content={alertContent}
        ></CommonModal>
      )}
      <Button onClick={handleClick} disabled={false} children={"다음"}></Button>
    </>
  );
};

export default DriverInfo;
